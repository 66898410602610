import { render, staticRenderFns } from "./ChecklistRadio.component.vue?vue&type=template&id=67bd13b8&scoped=true&"
import script from "./ChecklistRadio.component.vue?vue&type=script&lang=ts&"
export * from "./ChecklistRadio.component.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67bd13b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadio,VRadioGroup})
