













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ChecklistElement, ElementStatus} from "@/models/checklistElement";

@Component({})
export default class ChecklistRadio extends Vue {

  @Prop({default: ''})
  public element!: ChecklistElement;

  public status?: ElementStatus | null = null;

  public radioOptions: Array<{
    status: ElementStatus,
    text: string
  }> = [{
    status: ElementStatus.okay, text: 'i.o.'
  }, {
    status: ElementStatus.notOkay, text: 'n.i.o',
  }, {
    status: ElementStatus.notAvailable, text: 'n.v'
  }];

  @Watch('element', { immediate: true })
  public elementChanged() {
    if (this.element) {
      this.status = this.element.elementStatus;
    }
  }

  public mounted() {
    this.status = this.element.elementStatus;
  }

  public onChange() {
    this.$emit('changed', this.status);
  }
}

